import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FoundationFinalComponent } from './foundation/foundation-final/foundation-final.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { SocialLoginModule } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from "angularx-social-login";
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

let config = [
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("778906552452-2brugj3e85f259qr1590kouehe0n7uvl.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("1462868567303632")
  }
];

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    FoundationFinalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MomentModule,
    SocialLoginModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [ 
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: provideConfig,
      useValue: 'en-EN' // 'de' for Germany, 'fr' for France ...
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

