import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { apiUrl, defaultToken, toFormData } from '../../utils';
import { map } from 'rxjs/operators';
const httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Basic cnpwX3Rlc3RfVkFzZVQ4elpCYm96dlU6T2lKMXB5TVVDVlY0Tm1nN2R5NVNoYlhG',
      }
    )
  };
@Injectable({
  providedIn: 'root'
})
export class AuthService {
dataStore: {
        token: string
        user: any
        isLoggedIn: boolean,
        rentLocation: string
    }
    private _token: BehaviorSubject<string>;
    private _rentLocation: BehaviorSubject<string>;
    private _user: BehaviorSubject<any>;
    private _isLoggedIn: BehaviorSubject<any>;
    private _onShowLogin: Subject<any> = new Subject();
    $logout: Subject<any> = new Subject();
    registerSuccess:any = null;
  constructor(
  	private http: HttpClient 
  ) {
        this.dataStore = {
            token: null,
            rentLocation: null,
            user: null,
            isLoggedIn: false
        }
        this._token = new BehaviorSubject(this.dataStore.token);
        this._user = new BehaviorSubject(this.dataStore.user);
        this._rentLocation = new BehaviorSubject(this.dataStore.rentLocation);
        this._isLoggedIn = new BehaviorSubject(this.dataStore.isLoggedIn);
    }


    public get $onShowLogin(): Observable<any> {
        return this._onShowLogin.asObservable();
    }

    public get $isLoggedIn(): Observable<any> {
        return this._isLoggedIn.asObservable();
    }

    public get isLoggedIn(): boolean {
        return this.dataStore.isLoggedIn;
    }

    public set isLoggedIn(value: boolean) {
        this.dataStore.isLoggedIn = value;
        this._isLoggedIn.next(value);
    }

    public get $token(): Observable<any> {
        return this._token.asObservable();
    }

    public get token(): any {
        return this.dataStore.token;
    }

    public set token(value: any) {
        this.dataStore.token = value;
        if (value) {
            localStorage.setItem('token', this.dataStore.token)
        } else {
            localStorage.removeItem('token')
        }
        this._token.next(this.dataStore.token);
    }

    public get $rentLocation(): Observable<any> {
        return this._rentLocation.asObservable();
    }

    public get rentLocation(): any {
        return this.dataStore.rentLocation;
    }

    public set rentLocation(value: any) {
        this.dataStore.rentLocation = value;
        if (value) {
            localStorage.setItem('rentLocation', this.dataStore.rentLocation)
        } else {
            localStorage.removeItem('rentLocation')
        }
        this._rentLocation.next(this.dataStore.rentLocation);
    }

    public get user(): any {
        return this.dataStore.user;
    }

    public get $user(): Observable<any> {
        return this._user.asObservable();
    }

    public set user(value: any) {
    
        this.dataStore.user = value;
        if (value) {
            localStorage.setItem('user', JSON.stringify(this.dataStore.user));
        } else {
            localStorage.removeItem('user')
        }
        this._user.next(Object.assign({}, this.dataStore).user);
        //console.log(JSON.stringify(this.dataStore.user));
    }
    
    
        showLogin() {
            this._onShowLogin.next(true);
        }

    register(request: any) {
        return this.http.post(apiUrl('beforeauth/usersignup'), toFormData({ Appuser: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {
            //this.user = data.data;
            //if (this.user ){
            //    this.token = this.user.auth_key;
            //    this.isLoggedIn = true;
            //}
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }
    userAddress(request: any) {
        return this.http.post(apiUrl('userauth/useraddress'), toFormData({ auth_key: this.user.auth_key, Useraddress: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    hcontactUs(request: any) {
        return this.http.post(apiUrl('userauth/hospitalitycontactus'), toFormData({ auth_key: this.user.auth_key, Hospitalitycontactus: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    SponsorcontactUs(request: any) {
        return this.http.post(apiUrl('userauth/sponsorsshipcontactus'), toFormData({ auth_key: this.user.auth_key, Sponsorsshipcontactus: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

     getHospitality() {
        return this.http.post(apiUrl('userauth/gethospitality'), toFormData({ auth_key: this.user.auth_key}), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    getSponsorship() {
        return this.http.post(apiUrl('userauth/getsponsorsship'), toFormData({ auth_key: this.user.auth_key}), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    getSeasonticket() {
        return this.http.post(apiUrl('userauth/getseasonticket'), toFormData({ auth_key: this.user.auth_key}), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    Checkout(request: any) {
        return this.http.post(apiUrl('userauth/checkout'), toFormData({ auth_key: this.user.auth_key, Userorder: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    Userticketbooking() {
        return this.http.post(apiUrl('userauth/userticketbookingdetail'), toFormData({ auth_key: this.user.auth_key }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    addCard(request: any) {
        return this.http.post(apiUrl('userauth/addcard'), toFormData(request), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    getClubsponsordetail(clubid: any) {
        return this.http.post(apiUrl('userauth/getclubsponsordetail'), toFormData({ auth_key: this.user.auth_key, club_sponsor_id: clubid }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    getuserAddress() {
        return this.http.post(apiUrl('userauth/getaddress'), toFormData({ auth_key: this.user.auth_key }), {
        }).toPromise()
        .then((data: any) => {
            return data;
        }).catch((error) => {
            throw error;
        })
    }

    getcardlist() {

        return this.http.post(apiUrl('userauth/listallcard'), toFormData({ auth_key: this.user.auth_key }), {
        }).toPromise()
        .then((data: any) => {
            return data;
        }).catch((error) => {
            throw error;
        })
    }

    updateAssign(request: any) {
        return this.http.post(apiUrl('userauth/updatematchticketassigndetail'), toFormData({ auth_key: this.user.auth_key, Matchticketassign: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    updateseasonAssign(request: any) {
        return this.http.post(apiUrl('userauth/updateseasonticketassigndetail'), toFormData({ auth_key: this.user.auth_key, Seasonticketassign: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }
    
    autologin(request: any) {
        return this.http.post(apiUrl('userauth/autologin'), toFormData({ Appuser: request, auth_key:  this.user.auth_key}))
        .toPromise()
        .then((data: any) => {
            return data;
        }).catch((error) => {
            throw error;
        })
    }

    applayPromocode(request: any) {
        return this.http.post(apiUrl('userauth/applypromocode'), toFormData({ Promocode: request, auth_key:  this.user.auth_key}))
        .toPromise()
        .then((data: any) => {
            return data;
        }).catch((error) => {
            throw error;
        })
    }


    updateProfile(request: any) {
        return this.http.post(apiUrl('userauth/updateprofile'), toFormData({ auth_key: this.user.auth_key, Appuser: request }), {
            headers: {
                ...defaultToken()
            }
        })
        .toPromise()
        .then((data: any) => {            
            return data;
        }).catch((error) => {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            throw error;
        })
    }

    login(request: any) {
        return this.http.post(apiUrl('beforeauth/login'), toFormData({ Appuser: request }), {
            headers: {
                ...defaultToken()
            }
        }).toPromise()
        .then((data: any) => {
                if (data.data && data.data.auth_key) {
                    this.user = data.data;
                    this.token = this.user.auth_key;
                    this.isLoggedIn = true;
                }else{
                    throw data.message;
                }
                return data;
            }).catch((error) => {
                this.isLoggedIn = false;
                this.token = null;
                this.user = null;
                throw error;
            })
           
    }
    sociallogin(request: any) {
        return this.http.post(apiUrl('beforeauth/sociallogin'), toFormData({ Appuser: request }), {
            headers: {
                ...defaultToken()
            }
        }).toPromise()
            .then((data: any) => {
                if (data.data && data.data.auth_key) {
                    this.user = data.data;
                    this.token = this.user.auth_key;
                    this.isLoggedIn = true;
                }else{
                    throw data;
                    //console.log('testdata');
                }
                return data;
            }).catch((error) => {
                this.isLoggedIn = false;
                this.token = null;
                this.user = null;
                throw error;
            })
    }
    getClubsponsor() {
        return this.http.post(apiUrl('userauth/getclubsponsor'), toFormData({ auth_key: this.user.auth_key }), {
        }).toPromise()
        .then((data: any) => {
            return data;
        }).catch((error) => {
            throw error;
        })
    }
    getMatchticket() {
        return this.http.post(apiUrl('userauth/getmatchticketlist'), toFormData({ auth_key: this.user.auth_key }), {
        }).toPromise()
        .then((data: any) => {
            return data;
        }).catch((error) => {
            throw error;
        })
    }
    getProduct() {
        return this.http.post(apiUrl('userauth/getproductcategory'), toFormData({ auth_key: this.user.auth_key }), {
        }).toPromise()
        .then((data: any) => {
            return data;
        }).catch((error) => {
            throw error;
        })
    }
    getSingleproduct(request: any) {
        return this.http.post(apiUrl('userauth/getproductcategorydetail'),toFormData({auth_key: this.user.auth_key, Productcategory: request})).toPromise().then((data:any)=>{
            return data;
        })
    }
    
    getSinglematchticket(request: any) {
        return this.http.post(apiUrl('userauth/getmatchticketdetail'),toFormData({auth_key: this.user.auth_key, Matchticket: request})).toPromise().then((data:any)=>{
            return data;
        })
    }
    getUserticketlist() {
        return this.http.post(apiUrl('userauth/userticketcartlist'), toFormData({ auth_key: this.user.auth_key}))
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }

    getUserlist() {
        return this.http.post(apiUrl('userauth/usercartlist'), toFormData({ auth_key: this.user.auth_key}))
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }

    getUserseasonticketlist() {
        return this.http.post(apiUrl('userauth/userseasonticketcartlist'), toFormData({ auth_key: this.user.auth_key}))
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }
    getUsercart(request: any) {
        return this.http.post(apiUrl('userauth/usercart'),toFormData({auth_key: this.user.auth_key, Usercarts: request})).toPromise().then((data:any)=>{
            return data;
        })
    }
    getAddress() {
        return this.http.post(apiUrl('userauth/getaddress'), toFormData({ auth_key: this.user.auth_key}))
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }
    logout() {
        this.http.post(apiUrl('userauth/logout'), toFormData({ auth_key:  this.user.auth_key}))
        .toPromise()
        .then((data: any) => {
            
        }).catch((error) => {
            throw error;
        })
        return new Promise((resolve) => {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("bookingData");
            localStorage.removeItem("rentLocation");
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            this.$logout.next();
            resolve(true);
        });
    }

    async init() {
        this.token = localStorage.getItem('token');
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.token && this.user && this.user.appuser_id) {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    }

}
