import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('src/app/layout/layout.module').then(m => m.LayoutModule)
  },
  { 
    path: '**',
    redirectTo: '/notfound'
  },
  ];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    paramsInheritanceStrategy: 'always'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
